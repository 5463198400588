.Typography {
  text-align: left !important;
  font-weight: 400 !important;
  font-size: "18px" !important;
}
.fieldsContainer {
  display: flex;
  gap: 20px;
  width: 100%;
}

.leftColumn,
.rightColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.signupElement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}
