.rowsContainer {
  margin: 0px 25px;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.actionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loadMore {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    border: none;
    width: 20%;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  text-align: left;
}
