.card {
  text-align: left;
  margin: "2px";
  box-shadow: 3;
}
.card:hover {
  box-shadow: 6;
}
.ClientElement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
