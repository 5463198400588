.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url("../imgs/Group\ 1.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.signupElement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;

  .logo {
    height: 80px;
    width: auto;
    cursor: pointer;
  }

  .right-side {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 5px;
    }
  }
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;

  .card {
    width: 90%;

    max-width: 480px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      top: auto;
    }
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  label {
    font-size: 14px;
    margin-bottom: 5px;
  }
}

.forgotPasswordLink {
  font-size: 12px;
  position: relative;
  left: 40%;
  text-decoration: none;
  margin-top: 5px;

  &:hover {
    text-decoration: underline;
  }
}

button {
  width: 100%;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center;

    .logo {
      height: 60px;
    }
  }

  .content {
    padding: 10px;
  }
}
.la1 {
  position: relative;
  right: 45%;
}
.la {
  position: relative;
  right: 42%;
}
.under {
  text-decoration: none;
  margin-top: 5px;

  &:hover {
    text-decoration: underline;
  }
}
