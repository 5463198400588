.layoutRightSideContainer {
  flex: 10;
  padding-right: 3vw;
  padding-left: 1vw;
}

.gap {
  text-align: left;
  font-weight: 600;
  font-size: 32px;
  display: flex;
  align-items: center;
}

.card {
  border-radius: 16px 16px !important;
  margin-bottom: 1vh;
  min-height: 500px;
  max-height: 80vh;
  // border: 1px red solid;
}

.cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 16px 16px 0px 0px;
  height: 35px;
}
.contentContainer {
  margin-bottom: 15px;
  margin-left: 15px;
  margin-top: 15px;
  padding-right: 15px;
  //55 = (35+(10+10)) header height+ padding top & padding bottom
  //30 = (15 + 15) margin top & margin bottom
  height: calc(80vh - calc(55px + 30px));
  overflow-y: scroll;
  // border: 1px blue solid;
}
