.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: "auto";
}
.timer1 {
  width: 110px;
  height: 110px;
  position: fixed;
  top: 25%;
  left: 19%;
  background-image: url("../imgs/timer.png");
  z-index: 5;
}

.timer2 {
  width: 110px;
  height: 110px;
  position: fixed;
  top: 65%;
  right: 19%;
  background-image: url("../imgs/timer.png");
  z-index: 5;
}
.checklist1 {
  width: 83px;
  height: 83px;
  position: fixed;
  top: 65%;
  left: 10%;
  background-image: url("../imgs/checklist.png");
  z-index: 5;
}
.checklist2 {
  width: 83px;
  height: 83px;
  position: fixed;
  top: 25%;
  right: 10%;
  background-image: url("../imgs/checklist.png");
  z-index: 5;
}
.backgroundContainer {
  // border: solid 1px;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.tri {
  background-repeat: no-repeat;
  position: absolute;
  height: 100vh;
  // border: solid 1px red;
}
.tri1 {
  width: 25vw;
  background-size: cover;
  background-image: url("../imgs/tri4.png");
}
.tri2 {
  left: 10vw;
  width: 49vw;
  background-size: cover;
  background-image: url("../imgs/tri3.png");
}
.tri3 {
  left: 42vw;
  width: 39vw;
  background-size: cover;
  background-image: url("../imgs/tri2.png");
}
.tri4 {
  right: 0vw;
  width: 30vw;
  background-size: cover;
  background-image: url("../imgs/tri1.png");
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 0 20px;
  .logo {
    height: 80px;
    width: auto;
    cursor: pointer;
  }

  .right-side {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    width: 25vw;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 5px;
    }
  }
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .card {
    width: 39vw !important;
    max-width: 500px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    max-height: 75vh !important;
    overflow: auto;
    border-radius: 15px;

    @media (max-width: 768px) {
      top: auto;
    }
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  label {
    font-size: 14px;
    margin-bottom: 5px;
  }
}

.forgotPasswordLink {
  font-size: 12px;
  position: relative;
  left: 40%;
  text-decoration: none;
  margin-top: 5px;

  &:hover {
    text-decoration: underline;
  }
}

button {
  width: 100%;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center;

    .logo {
      height: 60px;
    }
  }

  .content {
    padding: 10px;
  }
}
.la1 {
  position: relative;
  right: 45%;
}
.la {
  position: relative;
  right: 42%;
  top: 0;
}
.under {
  text-decoration: none;
  margin-top: 5px;

  &:hover {
    text-decoration: underline;
  }
}
