.projectsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.card {
  padding: 10px;
  width: 63vw;
  height: 40vh;
  margin-bottom: 24px;
  position: relative;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.cardContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 24px;
}

.header {
  width: 20%;
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.headerTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
}

.rightSection {
  width: 30%;
  padding: 16px;
  border-radius: 8px;
  position: relative;
  top: -35px;
}

.iconContainer {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  border-radius: 8px;
}

.iconButton {
  padding: 0;
}

.cardText {
  margin-top: 16px;
  color: #666;
}

.btn {
  width: 48px !important;
  height: 55px !important;
  padding: 12px !important;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #b5b3b3 !important;
  margin-bottom: 10px !important;
}
* {
  font-family: "Cairo" !important;
}

.sectionTitle {
  margin-bottom: 16px;
}

.showAllButton {
  margin-bottom: 16px;
}

.table {
  border: none;
  margin-top: 16px;
  min-height: 50vh;
}

.tableSection {
  width: 100%;
  margin-top: 16px;
}

.topLeftSection {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
}

.homeIcon {
  width: 24px;
  height: 24px;
}

.homeText {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.Text {
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  text-decoration-skip-ink: none;
}
