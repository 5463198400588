.ClientElement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.rowsContainer {
  margin: 0px 25px;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.actionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
