.ClientElement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.clientsList {
  padding: 20px;

  h2,
  h3 {
    text-align: left;
    font-weight: 600;
    font-size: 20px;
  }

  hr {
    margin-bottom: 20px;
  }

  .listContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .clientCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #9f9c9c;
    }
  }

  .clientInfo {
    display: flex;
    align-items: center;
    gap: 15px;
    flex: 9;

    .circle {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
    }

    .details {
      h3 {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
      }

      .contactInfo {
        display: flex;
        gap: 20px;

        div {
          display: flex;
          align-items: center;
          gap: 8px;

          img {
            width: 16px;
            height: 16px;
          }

          span {
            font-size: 14px;
          }
        }
      }
    }
  }

  .clientMenu {
    display: flex;
    align-items: center;
    flex: 1;

    .menuButton {
      background: none;
      border: none;
      cursor: pointer;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        border-radius: 50%;
      }
    }
  }
}
.invoiceProjects {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
  flex: 2;

  .invoice,
  .projects {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;

    img {
      width: 24px;
      height: 24px;
    }

    span {
      font-size: 14px;
    }
  }
}
