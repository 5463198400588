.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ffffff;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 20px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Cairo", sans-serif;
}

.headerLeft,
.headerRight {
  display: flex;
  align-items: center;
}

.headerCenter {
  display: flex;
  align-items: center;
}

.logo {
  height: 48px;
  width: 185.53px;
}

.icon {
  margin-left: 15px;
  font-size: 18px;
  cursor: pointer;
}

.searchButton {
  margin-right: 20px !important;
  width: auto !important;
  height: auto !important;
}

.search {
  width: "13%" !important;
}

// Responsive styles
@media (max-width: 768px) {
  .header {
    padding: 10px;
  }

  .searchButton {
    width: auto !important;
    margin-bottom: 10px;
  }

  .headerRight {
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
  }

  .logo {
    width: 120px;
  }

  .icon {
    margin-left: 10px;
    font-size: 16px;
  }

  .searchButton {
    width: 100% !important;
    margin-bottom: 10px;
  }
}
