.drawer {
  flex: 2 !important;
}

.drawerPaper {
}
.menuItems {
  padding: 1px !important;
  padding-left: 0.75vw !important;
  border-radius: 0px 5px 5px 0px;
}

.entityNameContainer {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 0.5vw;
}

.iconContainer {
  margin-right: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
.iconText {
  text-align: center;
  font-size: 20px;
}
.entityName {
  font-size: 24px;
  flex: 1;
  text-align: left;
}
